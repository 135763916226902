//Test 1
import React, { useEffect, useState } from "react";
import Api from "./components/api/api";
import Loading from "./components/Loading";
import Alert from "./components/Alert";
import Variants from "./components/Variants";
import MonoOrderConfirm from "./components/MonoOrderConfirm";
import Lock from "./components/Lock";
import LegalPopup from "./components/LegalPopup";
import PagePopup from "./components/PagePopup";
import Pin from "./components/Pin";
import Name from "./components/Name";
import Covers from "./components/Covers";
import Page from "./components/Page";
import PopUps from "./components/PopUps";
import FlowPopUp from "./components/FlowPopUp";
import "./css/App.css";
import "./css/CookieMan.css";
import "./css/Products.css";
import "./css/ProductSheet.css";
import "./css/tempUserPopup.css";
import Lists from "./components/Lists";
import Socket from "./Socket";
import { io } from "socket.io-client";
import Label from "./components/Label";
import Cart from "./components/Cart";
import User from "./components/User";
import PromoPopup from "./components/PromoPopup";
import ProductSheet from "./components/ProductSheet";
import QrOrderConfirm from "./components/QrOrderConfirm";
import Orders from "./components/Orders";
import RelatedProductsPopup from "./components/RelatedProductsPopup";
import moment from "moment";
import Limits from "./components/Limits";
import { replaceAll } from "./components/functions/functions";
import EmailPopup from "./components/EmailPopup";
import { GoogleOAuthProvider } from "@react-oauth/google";
import KioskPaymentPopUp from "./components/KioskPaymentPopUp";
import KioskHome from "./components/KioskHome";
import TagManager from "react-gtm-module";
import QrPopup from "./components/QrPopup";
import { jwtDecode } from "jwt-decode";

export const SettingsContext = React.createContext();
export const ShopContext = React.createContext();
export const TableContext = React.createContext();
export const CategoriesContext = React.createContext();
export const CartContext = React.createContext();
export const AlertContext = React.createContext();
export const UserContext = React.createContext();

const hostname = window.location.hostname;
let socketUrl = false;
if (hostname.includes("localhost") || hostname.includes(".dev.") || hostname.includes(".demo."))
	socketUrl = "https://ws.devaws.yellgo.idspace.it:3000";
else socketUrl = "https://ws.yellgo.cloud";
const socket = io(socketUrl);

function App() {
	const queryParams = new URLSearchParams(window.location.search);
	const [lock, setLock] = useState(true);
	const [loading, setLoading] = useState(false);
	const [settings, setSettings] = useState(false);
	const [shop, setShop] = useState(false);
	const [table, setTable] = useState(false);
	const [brand, setBrand] = useState(false);
	const [pin, setPin] = useState(false);
	const [menu, setMenu] = useState([]);
	const [categories, setCategories] = useState([]);
	const [course, setCourse] = useState(false);
	const [category, setCategory] = useState(false);
	const [cart, setCart] = useState(false);
	const [alertData, setAlertData] = useState(false);
	const [monoOrder, setMonoOrder] = useState(false);
	const [user, setUser] = useState(false);
	const [userPopupVisible, setUserPopupVisible] = useState(false);
	const [listId, setListId] = useState(false);
	const [accessType, setAccessType] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(false);
	const [confirmAnimation, setConfirmAnimation] = useState(false);
	const [monoOrderConfirmVisible, setMonoOrderConfirmVisible] = useState(false);
	const [qrOrderConfirmVisible, setQrOrderConfirmVisible] = useState(false);
	const [isClosed, setIsClosed] = useState(false);
	const [isPaymentUser, setIsPaymentUser] = useState(false);
	const [productSheet, setProductSheet] = useState(false);
	const [userID] = useState(() => {
		if (localStorage.getItem("user_id")) return localStorage.getItem("user_id");
		else {
			const userID =
				Date.now() +
				"-" +
				Math.floor(Math.random() * 100) +
				"-" +
				Math.floor(Math.random() * 100);
			localStorage.setItem("user_id", userID);
			return userID;
		}
	});
	const [legal, setLegal] = useState(false);
	const [legalPopup, setLegalPupop] = useState(false);
	const [pagePopup, setPagePopup] = useState(false);
	const [promoPopup, setPromoPopup] = useState(false);
	const [lang, setLang] = useState(() => {
		return localStorage.getItem("lang") ? localStorage.getItem("lang") : false;
	});
	const [credit, setCredit] = useState(false);
	const [flowPopUp, setFlowPopUp] = useState(false);
	const [labels, setLabels] = useState(false);
	const [cartVisible, setCartVisible] = useState(false);
	const [discounts, setDiscounts] = useState(false);
	const [ordersVisible, setOrdersVisible] = useState(false);
	const [isMaintenance, setIsMaintenance] = useState(false);
	const [relatedProducts, setRelatedProducts] = useState(false);
	const [loadRelated, setLoadRelated] = useState(true);
	const [timerActive, setTimerActive] = useState(false);
	const [emailPopupVisible, setEmailPopupVisible] = useState(false);
	const [showPaymentPopup, setShowPaymentPopup] = useState(false);
	const [marketing, setMarketing] = useState(0);
	const [newsletter, setNewsletter] = useState(0);
	const [singleOrderMode, setSingleOrderMode] = useState(false);
	const [productNotesPopup, setProductNotesPopup] = useState(false);
	const [kioskMode, setKioskMode] = useState(false);
	const [kioskPaymentPopUpVisible, setKioskPaymentPopUpVisible] = useState(false);
	const [pages, setPages] = useState([]);
	const [stock, setStock] = useState([]);
	const [qrPopupVisible, setQrPopupVisible] = useState(false);
	let timer;
	let timer_menu;

	useEffect(() => {
		init();
	}, []);

	useEffect(() => {
		if (shop && cart) {
			if (shop.selfordering_round_time && !monoOrder) {
				check_round_time();
				timer = setInterval(check_round_time, 1000);
			}
		}
		return () => clearInterval(timer);
	}, [cart, shop.monoOrder]);

	useEffect(() => {
		if (settings) {
			applyCustomCss();
			initLang();
			if (settings.selfordering.maintenance == 1) setIsMaintenance("maintenance");
		}
	}, [settings]);

	useEffect(() => {
		if (settings) {
			if (!localStorage.getItem("customer_id") && settings.selfordering.login_at_start == 1)
				setUserPopupVisible(true);
		}
	}, [user, settings]);

	useEffect(() => {
		if (settings && brand) {
			applyBrandCss();
		}
	}, [brand]);

	useEffect(() => {
		console.log("accessType set to", accessType);
	}, [accessType]);

	useEffect(() => {
		console.log("pin set to", pin);
	}, [pin]);

	useEffect(() => {
		if (menu) {
			filterMenu();
			timer_menu = setInterval(filterMenu, 60000);
		}
		return () => {
			console.log("clear timer_menu");
			clearInterval(timer_menu);
		};
	}, [menu, shop, listId, lang, settings, discounts]);

	useEffect(() => {
		if (settings && categories && pin) {
			if (!course && !category && settings.selfordering.hide_home == 1) {
				if (categories.length > 0 && categories[0].categories.length > 0) {
					console.log("AAA", course, category, settings.selfordering.hide_home);
					setCourse(categories[0]);
					setCategory(categories[0].categories[0]);
				}
			}
			if (
				!course &&
				(categories.length === 1 || settings.selfordering.categories_menu_type == 2)
			) {
				setCourse(categories[0]);
			}
			if (course) {
				console.log("update selected");
				const courseExist = categories.find((c) => c.id == course.id);
				if (courseExist) {
					setCourse(courseExist);
					if (category) {
						const categoryExist = courseExist.categories.find(
							(c) => c.id == category.id
						);
						if (!categoryExist) setCategory(courseExist.categories[0]);
						else setCategory(categoryExist);
					}
				} else setDefaultCourse();
			}
		}
	}, [categories, settings, pin]);

	useEffect(() => {
		console.log("course set to", course);
	}, [course]);

	useEffect(() => {
		console.log("category set to", category);
	}, [category]);

	useEffect(() => {
		if (shop && table && pin) {
			loadCart();
		}
	}, [shop, table, pin, lang, user, isPaymentUser, credit, settings]);

	useEffect(() => {
		if (confirmAnimation)
			setTimeout(function () {
				setConfirmAnimation(false);
			}, 1000);
	}, [confirmAnimation]);

	useEffect(() => {
		console.log("lang set to", lang);
		if (lang) document.documentElement.lang = lang;
	}, [lang]);

	useEffect(() => {
		console.log("loadRelated set to", loadRelated);
	}, [loadRelated]);

	useEffect(() => {
		if (table) setSingleOrderMode(table.single_order_mode == 1);
	}, [table]);

	useEffect(() => {
		console.log("singleOrderMode set to", singleOrderMode);
	}, [singleOrderMode]);

	useEffect(() => {
		if (monoOrder && shop && settings) {
			if (settings.selfordering.force_login == 1) {
				open_temp_cart(shop.id);
			}
		}
	}, [monoOrder, shop, settings]);

	async function init() {
		console.log("init");

		await Api.oauth();
		const temp_settings = await loadSettings();
		await loadLabels();
		await load_legal();
		await loadPages();
		await loadDiscounts();
		await loadProductsStock();

		const shopID = await getShopId();
		const tableID = queryParams.get("table_id");
		const monoOrderParam = queryParams.get("mono_order");
		const customerID = queryParams.get("customer_id");
		const token = queryParams.get("token");
		const showConfirm = queryParams.get("show_confirm");
		const initPin = queryParams.get("pin");
		let brandID = queryParams.get("brand_id");
		const initFunction = queryParams.get("f");
		const orderId = queryParams.get("order_id");
		const fiscalPrinterId = queryParams.get("fiscal_printer_id");

		if (fiscalPrinterId) {
			setKioskMode(fiscalPrinterId);
			document.getElementById("root").classList.add("kiosk");
		}

		if (showConfirm == 1) {
			if (monoOrderParam) setMonoOrderConfirmVisible({ id: orderId });
			else if (orderId) setQrOrderConfirmVisible({ id: orderId });
			else {
				setAlertData({
					message: <Label number={48} />,
					confirmMessage: <Label number={31} />,
				});
			}
		}

		if (initFunction == "new_password") {
			setUserPopupVisible({ id: queryParams.get("id"), step: 7 });
		}

		let shop;
		if (shopID) shop = await load_shop(shopID);
		if (monoOrderParam) {
			setAccessType("mono");
			setMonoOrder(true);
			localStorage.removeItem("pin");
		} else if (shopID && tableID) {
			setAccessType("shop");
			let table = await load_table(tableID);
			if (table.single_order_mode == 1) {
				const openResponse = await Api.post("/self_cart/open/" + tableID + "/");
				if (openResponse.success === 0)
					setAlertData({
						title: <Label number={70} />,
						message: openResponse.error,
						confirmMessage: <Label number={31} />,
					});
				else {
					setPin("not_required");
				}
			} else {
				if (initPin) localStorage.setItem("pin", initPin);
				await checkPin(temp_settings, shop, table);
			}
		} else {
			setAccessType("table");
			if (initPin) localStorage.setItem("pin", initPin);
			await checkPin(temp_settings);
		}

		if (token) await load_customer_by_token(token);
		else if (localStorage.getItem("selforder-token"))
			await load_customer_by_token(localStorage.getItem("selforder-token"));

		if (customerID) await load_customer(customerID);
		else if (localStorage.getItem("customer_id"))
			await load_customer(localStorage.getItem("customer_id"));

		if (!brandID && shopID) brandID = await getDefaultBrand(shopID, temp_settings);
		if (brandID) await load_brand(brandID);

		await load_menu();

		if (temp_settings.selfordering.googletagmanager) {
			console.log("Init Tag Manager");
			const tagManagerArgs = {
				gtmId: temp_settings.selfordering.googletagmanager,
			};
			TagManager.initialize(tagManagerArgs);
		}

		setLock(false);
	}

	const getShopId = async () => {
		if (queryParams.get("shop_id")) return queryParams.get("shop_id");
		else if (queryParams.get("brand_id")) {
			const brand = await Api.post("/brands/get/" + queryParams.get("brand_id") + "/");
			if (brand.success == 0) alert(brand.error);
			else {
				if (brand.data.shops.length > 0) return brand.data.shops[0].id;
			}
		}
	};

	const initLang = () => {
		if (queryParams.get("lang")) localStorage.setItem("lang", queryParams.get("lang"));
		const storedLang = localStorage.getItem("lang");
		const browserLang = window.navigator.userLanguage || window.navigator.language;
		console.log("initLang", storedLang, browserLang);
		console.log(window.navigator);
		if (settings.selfordering.multilanguage == 0) setLang("it");
		else {
			if (!lang || settings.global[lang] == 0) {
				if (settings.global.it == 1 && browserLang == "it-IT") setLang("it");
				else if (settings.global.zh == 1 && browserLang == "zh-CN") setLang("zh");
				else if (settings.global.en == 1) setLang("en");
				else if (settings.global.zh == 1) setLang("zh");
				else setLang("it");
			}
		}
	};

	async function loadSettings() {
		console.log("loadSettings");
		const response = await Api.post("/settings2/get/", {
			section: [
				"global",
				"selfordering",
				"google",
				"fidelity",
				"customers",
				"orders",
				"menu",
				"credit",
				"menu_api",
			],
		});
		setSettings(response.data);

		document.title = "Selforder | " + response.data.global.name;
		var link = document.querySelector("link[rel*='icon']") || document.createElement("link");
		link.type = "image/x-icon";
		link.rel = "shortcut icon";
		link.href = response.data.selfordering.favicon;
		document.getElementsByTagName("head")[0].appendChild(link);

		return response.data;
	}

	const loadLabels = async () => {
		console.log("loadLabels");
		const response = await Api.post("/labels/list/", {
			type: "selforder",
		});
		setLabels(response.rows);
	};

	const loadPages = async () => {
		console.log("loadLabels");
		const response = await Api.post("/pages/list/", {
			src: [{ name: "selforder", compare: "equal", value: 1 }],
		});
		setPages(response.rows);
	};

	const loadDiscounts = async () => {
		console.log("loadDiscounts");
		const res = await Api.post("/discounts_products/list/");
		if (res.success == 0) alert(res.error);
		else setDiscounts(res.rows);
	};

	async function loadProductsStock() {
		const response = await Api.post("/products_stock/list_for_clients/");
		if (response.success == 0) alert(response.error);
		else setStock(response.rows);
	}

	async function load_legal() {
		console.log("load_legal");
		const response = await Api.post("/legal/get/");
		if (response.success === 0) alert(response.error);
		setLegal(response.data);
	}

	async function load_shop(shopID) {
		console.log("load_shop");
		setLoading(true);
		const response = await Api.post("/shops/get/" + shopID + "/");
		setLoading(false);
		if (response.success === 0) alert(response.error);
		setShop(response.data);
		return response.data;
	}

	async function load_table(tableID) {
		console.log("load_table");
		const response = await Api.post("/tables/get/" + tableID + "/");
		if (response.success === 0) alert(response.error);
		setTable(response.data);
		return response.data;
	}

	const checkPin = async (tempSettings, shop = false, table = false) => {
		console.log("checkPin(" + tempSettings.selfordering.pin_mode + ")");
		const p = localStorage.getItem("pin");
		if (p) {
			if (tempSettings.selfordering.pin_mode == "disabled") {
				if (p == "not_required") setPin("not_required");
			}
			if (
				tempSettings.selfordering.pin_mode == "generated" ||
				tempSettings.selfordering.pin_mode == "fixed"
			) {
				const response = await Api.post("/self_cart/check_pin/", {
					pin: p,
					lang: lang,
				});
				if (response.success === 0) localStorage.removeItem("pin");
				else {
					if (table && table.id != response.data.id) localStorage.removeItem("pin");
					else {
						load_shop(response.data.shop_id);
						load_table(response.data.id);
						setPin(p);
						localStorage.setItem("pin", p);
					}
				}
			}
			if (tempSettings.selfordering.pin_mode == "shop") {
				if (parseInt(shop.selfordering_pin) == parseInt(p)) {
					setPin(p);
				} else {
					localStorage.removeItem("pin");
				}
			}
		}
	};

	async function load_brand(brandID) {
		console.log("load_brand");
		const response = await Api.post("/brands/get/" + brandID + "/");
		if (response.success === 0) alert(response.error);
		setBrand(response.data);
		localStorage.setItem("brand_id", brandID);
	}

	async function load_customer(customerID) {
		console.log("load_customer");
		const response = await Api.post("/customers/get/" + customerID + "/");
		if (response.success === 0) alert(response.error);
		setUser(response.data);
	}

	async function load_customer_by_token(token) {
		console.log("load_customer_by_token");
		const decoded = jwtDecode(token);
		console.log("decoded", decoded);
		const response = await Api.post("/customers/get/" + decoded.user_id + "/");
		if (response.success === 0) alert(response.error);
		else {
			setUser(response.data);
			localStorage.setItem("customer_id", response.data.id);
		}
	}

	const load_menu = async () => {
		console.log("load_menu");
		const response = await Api.post("/menu/courses/");
		if (response.success === 0) alert(response.error);
		else {
			setMenu(response.rows);
		}
	};

	function filterMenu() {
		console.log("filterMenu", lang);
		let res = [];
		for (const course of menu) {
			let cr = JSON.parse(JSON.stringify(course));
			cr = getTranslations(cr, ["name"]);
			cr.categories = [];
			for (const category of course.categories) {
				if (
					checkCategoryTimes(category) &&
					(!category.hasOwnProperty("active_selfordering") ||
						category.active_selfordering == 1)
				) {
					let ct = JSON.parse(JSON.stringify(category));
					ct = getTranslations(ct, ["name", "description"]);
					ct = getTagsTranslations(ct);
					ct.products = [];
					for (const product of category.products) {
						if (
							!product.hasOwnProperty("active_selfordering") ||
							product.active_selfordering == 1
						) {
							let p = filterVariants(product);
							p = getTranslations(p, ["name", "description"]);
							p = getIngredientsTranslations(p, ["name", "description"]);
							p = getTagsTranslations(p);
							p = getLimitGroupsTranslations(p);
							p.stock = getProductStock(p.id);
							if (shop && listId) {
								const priceList = product.prices.filter((el) => {
									return el.list_id == listId && el.price;
								});
								const assortment = product.assortment.filter((el) => {
									return (
										el.shop_id == shop.id &&
										el.list_id == listId &&
										el.active == 1
									);
								});
								if (
									priceList.length > 0 &&
									assortment.length > 0 &&
									checkIngredientsAssortment(product)
								) {
									p.price = priceList[0].price;
									ct.products.push(p);
								}
							} else if (shop) {
								if (checkIngredientsAssortment(product)) {
									ct.products.push(p);
								}
							} else {
								ct.products.push(p);
							}
						}
					}
					if (ct.products.length > 0) cr.categories.push(ct);
				}
			}
			if (cr.categories.length > 0) res.push(cr);
		}
		applyDiscounts(res);
		setCategories(res);
	}

	const filterVariants = (product) => {
		let p = JSON.parse(JSON.stringify(product));
		p.variants_categories = [];
		for (const variants_category of product.variants_categories) {
			//console.groupCollapsed(variants_category.name);
			let vc = JSON.parse(JSON.stringify(variants_category));
			vc = getTranslations(vc, ["name"]);
			vc.variants = [];
			vc.variants2 = [];
			vc.products = [];
			for (const variant of variants_category.variants) {
				//console.log(variant.name);
				let v = JSON.parse(JSON.stringify(variant));
				v = getTranslations(v, ["name"]);
				if (shop && listId) {
					const priceList = variant.prices.filter((el) => {
						/*console.log(
							variant.name,
							el.list_id,
							listId,
							el.list_id == listId,
							el.price
						);*/
						return el.list_id == listId && el.price !== null;
					});
					const assortment = variant.assortment.filter((el) => {
						return el.shop_id == shop.id && el.list_id == listId && el.active == 1;
					});
					//console.log(priceList.length, assortment.length);
					if (priceList.length > 0 && assortment.length > 0) {
						v.price = priceList[0].price;
						vc.variants.push(v);
					}
				} else {
					vc.variants.push(v);
				}
			}
			for (const variant of variants_category.variants2) {
				let v = JSON.parse(JSON.stringify(variant));
				v = getTranslations(v, ["name"]);
				if (shop && listId) {
					const priceList = variant.prices.filter((el) => {
						/*console.log(
							variant.name,
							el.list_id,
							listId,
							el.list_id == listId,
							el.price
						);*/
						return el.list_id == listId && el.price !== null;
					});
					const assortment = variant.assortment.filter((el) => {
						return el.shop_id == shop.id && el.list_id == listId && el.active == 1;
					});
					if (priceList.length > 0 && assortment.length > 0) {
						v.price = priceList[0].price;
						vc.variants2.push(v);
					}
				} else {
					vc.variants2.push(v);
				}
			}
			for (const variant of variants_category.products) {
				//console.log(variant.name);
				let v = JSON.parse(JSON.stringify(variant));
				v = getTranslations(v, ["name"]);
				if (shop && listId) {
					const priceList = variant.prices.filter((el) => {
						return el.list_id == listId && el.price !== null;
					});
					const assortment = variant.assortment?.filter((el) => {
						return el.shop_id == shop.id && el.list_id == listId && el.active == 1;
					});
					//console.log(priceList.length);
					if (
						priceList.length > 0 &&
						assortment.length > 0 &&
						checkIngredientsAssortment(variant)
					) {
						v.price = priceList[0].price;
						vc.products.push(v);
					}
				} else if (shop) {
					if (checkIngredientsAssortment(variant)) {
						vc.products.push(v);
					}
				} else {
					vc.products.push(v);
				}
			}
			if (vc.variants.length > 0 || vc.variants2.length > 0 || vc.products.length > 0)
				p.variants_categories.push(vc);
			//console.groupEnd();
		}
		return p;
	};

	const getProductStock = (productId) => {
		if (!stock) return false;
		const s = stock.find((e) => e.shop_id == shop.id && e.product_id == productId);
		if (s) return s.qty;
		return false;
	};

	const applyDiscounts = (courses) => {
		//console.log("applyDiscount");
		for (const course of courses) {
			for (const category of course.categories) {
				for (const product of category.products) {
					if (discounts && discounts.length > 0 && shop.id) {
						for (const discount of discounts) {
							if (product.id == discount.product_id) {
								if (
									(!discount.shops_ids || discount.shops_ids.includes(shop.id)) &&
									(!discount.lists_ids || discount.lists_ids.includes(listId)) &&
									(!discount.date_start ||
										moment().startOf("day") >= moment(discount.date_start)) &&
									(!discount.date_end ||
										moment().startOf("day") <= moment(discount.date_end)) &&
									(!discount.qty || discount.qty > 0)
								) {
									product.discounted = true;
									product.discount = {
										category: "discounts_products",
										type: discount.type,
										value: discount.value,
										price:
											discount.type == "perc"
												? product.price -
												  (product.price / 100) * discount.value
												: product.price - discount.value,
									};
								}
							}
						}
					} else {
						product.discounted = false;
						product.discount = null;
					}
				}
			}
		}
	};

	const checkIngredientsAssortment = (product) => {
		if (!settings.menu_api) return true;
		if (!settings.menu_api.enable_ingredients_assortment) return true;
		if (settings.menu_api.enable_ingredients_assortment != 1) return true;
		if (!product.ingredients) return true;
		if (product.ingredients.length == 0) return true;
		for (const ingredient of product.ingredients) {
			const assortment = ingredient.assortment.filter((el) => {
				return el.shop_id == shop.id && el.active == 1;
			});
			if (assortment.length == 0) return false;
		}
		return true;
	};

	const checkCategoryTimes = (category) => {
		if (settings.orders.enable_categories_times == 0) return true;

		const d = new Date();
		let day = d.getDay();
		day = day - 1;
		if (day < 0) day = 6;
		if (category.times != null) {
			let times = category.times[day];
			let enabled = false;

			let now = d.getHours() * 100 + d.getMinutes();
			for (let service = 1; service <= 2; service++) {
				let open_h = times["s" + service].open.h;
				let open_m = times["s" + service].open.m;
				let close_h = times["s" + service].close.h;
				let close_m = times["s" + service].close.m;

				if (open_h != "" && close_h != "") {
					if (parseInt(close_h) === 0) {
						close_h = 24;
						close_m = 0;
					}
					if (parseInt(close_h) < parseInt(open_h)) {
						close_h = 24;
						close_m = 0;
					}
					open_h = open_h * 100;
					close_h = close_h * 100;
					let t_open = open_h + parseInt(open_m);
					let t_close = close_h + parseInt(close_m);
					if (now >= t_open && now <= t_close) enabled = true;
					console.log(service, now, t_open, t_close);
				}
			}
			if (enabled) return true;
		}
		return false;
	};

	const setCategoryHandler = async (cr, cat) => {
		console.log("setCategoryHandler");
		setCourse(cr);
		setCategory(cat);
		if (settings.selfordering.products_loading_type == "onepage") {
			if (course && category) {
				console.log("scroll A");
				const element = document.getElementById("category-" + cat.id);
				if (element) {
					element.scrollIntoView({ behavior: "smooth" });
				}
			} else {
				console.log("scroll B");
				setTimeout(function () {
					const element = document.getElementById("category-" + cat.id);
					if (element) {
						element.scrollIntoView({ behavior: "smooth" });
					}
				}, 500);
			}
		}
	};

	const getTranslations = (el, fields = ["name"]) => {
		for (const field of fields) {
			if (el.translations && lang == "en")
				el[field] = el.translations.en[field]
					? el.translations.en[field]
					: el.translations.it[field];
			if (el.translations && lang == "fr")
				el[field] =
					el.translations.fr && el.translations.fr[field]
						? el.translations.fr[field]
						: el.translations.it[field];
			if (el.translations && lang == "zh")
				el[field] =
					el.translations.zh && el.translations.zh[field]
						? el.translations.zh[field]
						: el.translations.it[field];
			if (el.translations && lang == "es")
				el[field] =
					el.translations.es && el.translations.es[field]
						? el.translations.es[field]
						: el.translations.it[field];
			if (el.translations && lang == "de")
				el[field] =
					el.translations.de && el.translations.de[field]
						? el.translations.de[field]
						: el.translations.it[field];
		}
		return el;
	};

	const getIngredientsTranslations = (product) => {
		let p = JSON.parse(JSON.stringify(product));
		p.ingredients = [];
		for (const ingredient of product.ingredients) {
			let i = JSON.parse(JSON.stringify(ingredient));
			i = getTranslations(i, ["name"]);
			p.ingredients.push(i);
		}
		return p;
	};

	const getTagsTranslations = (item) => {
		let p = JSON.parse(JSON.stringify(item));
		p.tags = [];
		if (item.tags) {
			for (const tag of item.tags) {
				let i = JSON.parse(JSON.stringify(tag));
				i = getTranslations(i, ["name"]);
				p.tags.push(i);
			}
		}
		return p;
	};

	const getLimitGroupsTranslations = (product) => {
		let p = JSON.parse(JSON.stringify(product));
		p.limit_groups = [];
		if (product.limit_groups) {
			for (const limit_group of product.limit_groups) {
				if (limit_group) {
					let i = JSON.parse(JSON.stringify(limit_group));
					i = getTranslations(i, ["name"]);
					p.limit_groups.push(i);
				}
			}
		}
		return p;
	};

	const loadCart = async () => {
		console.log("loadCart", user.id);
		const response = await Api.post("/self_cart/get/" + table.id + "/", {
			mono_order: monoOrder,
			lang: lang,
			customer_id: user.id,
			shop_id: shop.id,
			user_id: userID,
			brand_id: brand ? brand.id : "",
			credit: credit,
		});
		setLoading(false);
		if (response.success == 1) {
			setCart(response);
			if (response.lock == 1) {
				if (response.lock_message != "") {
					setIsClosed(response.lock_message);
				} else {
					if (!isPaymentUser) setIsClosed("Invio ordine in corso");
					else setIsClosed(false);
				}
			} else setIsClosed(false);
			if (pin) {
				if (response.status == 0) {
					console.log(accessType);
					localStorage.removeItem("pin");
					setPin(false);
					if (accessType == "table") {
						localStorage.removeItem("shop_id");
						localStorage.removeItem("table_id");
						setShop(false);
						setTable(false);
					}
					setAlertData({
						message:
							response.status == 2 ? <Label number={62} /> : <Label number={63} />,
						confirmMessage: <Label number={31} />,
					});
				} else if (response.status == 2) {
					setIsClosed("Tavolo in attesa di pagamento");
				} else {
					setListId(response.list_id);
				}
			}
			return response;
		}
	};

	const editCart = async (request) => {
		console.log("editCart");
		const response = await Api.post("/self_cart/edit/" + table.id + "/", request);
		if (response.success === 0)
			setAlertData({
				message: response.error,
				confirmMessage: <Label number={31} />,
			});
		else await loadCart();
	};

	const editTable = async (request) => {
		console.log("editTable");
		const response = await Api.post("/tables/edit/" + table.id + "/", request);
		if (response.success === 0)
			setAlertData({
				message: response.error,
				confirmMessage: <Label number={31} />,
			});
		else {
			if (table) load_table(table.id);
			loadCart();
		}
	};

	const handleAddProduct = async (product) => {
		if (product.stock !== false && (product.stock == 0 || !checkCartQty(product))) {
			setAlertData({
				message: "Prodotto esaurito",
				confirmMessage: <Label number={57} />,
			});
			return;
		}
		add_product({
			product_id: product.id,
			qty: product.qty,
			variants: product.variants,
			products: product.products,
			notes: product.notes,
			has_price: product.price > 0 ? 1 : 0,
		});
	};

	const add_product = async (request, skip_alert = false) => {
		console.log("add_product", user.id, request);
		if (settings.selfordering.enable_flow == 1 && !request.flow) {
			setFlowPopUp(request);
			setSelectedProduct(false);
			return;
		}
		if (
			cart.allyoucaneat == 1 &&
			settings.selfordering.enable_ayce_price_popup == 1 &&
			request.has_price == 1 &&
			!skip_alert
		) {
			setAlertData({
				title: <Label number={102} />,
				message: <Label number={144} />,
				confirmMessage: <Label number={57} />,
				cancelMessage: <Label number={26} />,
				showCancelButton: true,
				callback: () => {
					add_product(request, true);
				},
			});
			return;
		}
		setFlowPopUp(false);
		request.mono_order = monoOrder;
		request.lang = lang;
		if (settings.selfordering.nicknames == 1)
			request.nickname = user ? user.name + " " + user.surname : cart.nickname;
		if (singleOrderMode) request.user_id = userID;
		setLoading(true);
		const response = await Api.post("/self_cart/add_product/" + table.id + "/", request);
		if (response.success === 0) {
			setLoading(false);
			setAlertData({
				message: response.error,
				confirmMessage: <Label number={31} />,
			});
		} else {
			setSelectedProduct(false);
			setConfirmAnimation(true);
			if (settings.menu_api?.enable_related_products_on_cart_add == 1) {
				if (loadRelated) getRelatedProducts(getCartProductsIds(), request.product_id);
				else setRelatedProducts(false);
			}
		}
	};

	function checkCartQty(product) {
		console.log("checkCartQty");
		let cartQty = 0;
		for (const p of cart.products) {
			if (p.product_id == product.id) cartQty += parseInt(p.qty);
		}
		console.log(cartQty);
		if (cartQty < product.stock) return true;
		return false;
	}

	const getRelatedProducts = async (ids, id = null) => {
		console.log("getRelatedProducts", ids);
		const response = await Api.post("/related_products/get/", { ids: ids, id: id });
		if (response.success === 0) {
			setAlertData({
				message: response.error,
				confirmMessage: <Label number={31} />,
			});
		} else {
			console.log(response);
			let res = [];
			for (const rpId of response.rows) {
				console.log(rpId);
				for (const course of categories) {
					for (const category of course.categories) {
						for (const product of category.products) {
							let p = JSON.parse(JSON.stringify(product));
							if (product.id == rpId) res.push(p);
						}
					}
				}
			}
			console.log(res);
			if (res.length > 0) setRelatedProducts(res);
			else setRelatedProducts(false);
		}
	};

	const updateQty = async (product_id, item_id, qty, price) => {
		console.log("updateQty", user.id);
		const p = cart.products.find((p) => p.item_id == item_id);
		const s = stock.find((s) => s.product_id == p.product_id && s.shop_id == shop.id);
		if (qty < p.qty || s === undefined || parseInt(s.qty) > parseInt(p.qty)) {
			setRelatedProducts(false);
			setLoading(true);
			const response = await Api.post("/self_cart/edit_product/" + table.id + "/?debug=1", {
				lang: lang,
				item_id: item_id,
				qty: qty,
				mono_order: monoOrder,
				has_price: price > 0 ? 1 : 0,
			});
			if (response.success === 0) {
				setLoading(false);
				setAlertData({
					message: response.error,
					confirmMessage: <Label number={31} />,
				});
			} else {
				if (settings.menu_api?.enable_related_products_on_cart_add == 1) {
					if (loadRelated) getRelatedProducts(getCartProductsIds(), product_id);
					else setRelatedProducts(false);
				}
			}
		}
	};

	const getCartProductsIds = () => {
		let res = [];
		for (const product of cart.products) {
			res.push(product.product_id);
		}
		return res;
	};

	const handleCheckout = (skipRegistration = false) => {
		console.log("handleCheckout", skipRegistration);
		if (kioskMode) {
			checkout(14);
		} else if (settings.selfordering.force_login == 1 && !user) {
			setUserPopupVisible(true);
		} else if (
			monoOrder &&
			settings.selfordering.enable_email == 1 &&
			!cart.email &&
			!user &&
			!skipRegistration
		) {
			setEmailPopupVisible(true);
		} else if (
			(singleOrderMode || (monoOrder && settings.selfordering.enable_payment_mono == 1)) &&
			cart.total > 0 &&
			shop.yellgo_pay?.active_selforder != 1
		) {
			setShowPaymentPopup(true);
		} else if (
			(singleOrderMode || (monoOrder && settings.selfordering.enable_payment_mono == 1)) &&
			cart.total > 0 &&
			shop.yellgo_pay?.active_selforder == 1
		) {
			setAlertData({
				title: <Label number={102} />,
				message: <Label number={126} />,
				showCancelButton: true,
				callback: init_yellgo_pay,
			});
		} else if (!monoOrder) {
			setAlertData({
				title: <Label number={49} />,
				message: <Label number={50} />,
				showCancelButton: true,
				callback: checkout,
			});
		} else {
			setAlertData({
				title: <Label number={103} />,
				message: <Label number={104} />,
				showCancelButton: true,
				callback: checkout,
			});
		}
	};

	async function checkout(paymentMethodId = null, payment_id = null) {
		console.log("checkout");
		setLoading(true);

		const response = await Api.post("/self_cart/checkout/" + table.id + "/", {
			kiosk_mode: kioskMode ? 1 : 0,
			shop_id: shop.id,
			mono_order: monoOrder,
			payment_method_id: paymentMethodId,
			payment_id: payment_id,
			customer_id: user.id,
			brand_id: brand ? brand.id : null,
			user_id: userID,
			timer_active: timerActive ? true : false,
			self_mode: accessType,
			credit: credit,
			fiscal_printer_id: kioskMode || null,
			customer: {
				id: user.id,
				name: user.name,
				surname: user.surname,
				email: user.email,
				area_code: user.area_code,
				mobile: user.mobile,
				customer_lang: user.customer_lang,
			},
		});
		setLoading(false);
		if (response.success == 1) {
			closeCart(response.data);
		} else {
			setAlertData({
				message: response.error,
				confirmMessage: <Label number={31} />,
			});
		}
	}

	const closeCart = (order) => {
		if (newsletter == 1) editCustomer("newsletter");
		if (marketing == 1) editCustomer("marketing");

		setShowPaymentPopup(false);
		setCartVisible(false);
		setCredit(false);
		if (kioskMode) setKioskPaymentPopUpVisible(order);
		else if (monoOrder) setMonoOrderConfirmVisible(order);
		else if (singleOrderMode) setQrOrderConfirmVisible(order);
		else
			setAlertData({
				message: <Label number={48} />,
				confirmMessage: <Label number={31} />,
			});
	};

	const init_yellgo_pay = async () => {
		console.log("init_yellgo_pay");
		const request = {
			mode: "single",
			shop_id: shop.id,
			amount: cart.total,
			origin: "selfordering",
			user_id: userID,
			customer_id: user.id,
			table_id: table.id,
			self_mode: accessType,
			data: {
				shop_id: shop.id,
				mono_order: monoOrder,
				customer_id: user.id,
				brand_id: brand ? brand.id : null,
				user_id: userID,
				timer_active: timerActive ? true : false,
				self_mode: accessType,
				credit: credit,
				customer: {
					id: user.id,
					name: user.name,
					surname: user.surname,
					email: user.email,
					area_code: user.area_code,
					mobile: user.mobile,
					customer_lang: user.customer_lang,
				},
			},
		};
		console.log(request);
		setLoading(true);
		const response = await Api.post("/pay/open/" + table.id + "/", request);
		setLoading(false);
		console.log(response);
		if (response.success === 0) alert(response.error);
		else {
			console.log(response.url);
			document.location = response.url;
		}
	};

	const editCustomer = async (name) => {
		console.log("editCustomer");
		const response = await Api.post("/customers/edit/" + user.id + "/", {
			[name]: 1,
		});
		console.log(response);
		if (response.success === 0)
			setAlertData({
				message: response.error,
				confirmMessage: <Label number={31} />,
			});
	};

	const applyCustomCss = () => {
		console.log("applyCustomCss");
		if (settings.selfordering) {
			var injectedStyles = document.createElement("style");
			injectedStyles.setAttribute("type", "text/css");
			injectedStyles.innerHTML = settings.selfordering.css;
			document.head.appendChild(injectedStyles);

			document.documentElement.style.setProperty("--c1", settings.selfordering.color1);
			document.documentElement.style.setProperty("--c1c", settings.selfordering.color2);
			document.documentElement.style.setProperty(
				"--c1o",
				settings.selfordering.color1 + "50"
			);
			document.documentElement.style.setProperty("--c2", settings.selfordering.color3);
			document.documentElement.style.setProperty("--c2c", settings.selfordering.color4);

			document.documentElement.style.setProperty(
				"--ch",
				settings.selfordering.color_logo1
					? settings.selfordering.color_logo1
					: settings.selfordering.color1
			);
			document.documentElement.style.setProperty(
				"--cho",
				settings.selfordering.color_logo2
					? settings.selfordering.color_logo2
					: settings.selfordering.color2
			);
		}
	};

	const applyBrandCss = () => {
		console.log("applyBrandCss");

		console.log("brand", brand);
		let newSettings = { ...settings };

		if (brand.img) newSettings.selfordering.logo = brand.img;

		for (const key in brand.settings.selfordering) {
			console.log(key, brand.settings.selfordering[key]);
			if (brand.settings.selfordering[key] != "")
				newSettings.selfordering[key] = brand.settings.selfordering[key];
		}

		document.documentElement.style.setProperty("--c1", newSettings.selfordering.color1);
		document.documentElement.style.setProperty("--c2", newSettings.selfordering.color2);
		document.documentElement.style.setProperty("--c3", newSettings.selfordering.color3);
		document.documentElement.style.setProperty("--c4", newSettings.selfordering.color4);
		document.documentElement.style.setProperty("--c1o", newSettings.selfordering.color1 + "10");

		document.documentElement.style.setProperty(
			"--ch",
			settings.selfordering.color_logo1
				? settings.selfordering.color_logo1
				: settings.selfordering.color1
		);
		document.documentElement.style.setProperty(
			"--cho",
			settings.selfordering.color_logo2
				? settings.selfordering.color_logo2
				: settings.selfordering.color2
		);

		setSettings(newSettings);
	};

	const getDefaultBrand = async (shopID, temp_settings) => {
		if (temp_settings.mandant.brands == 1) {
			console.log("getDefaultBrand");
			const response = await Api.post("/shops/get/" + shopID + "/");
			if (response.data.brands.length > 0) return response.data.brands[0].id;
		}
		return false;
	};

	const setDefaultCourse = () => {
		console.log("setDefaultCourse");
		if (categories.length > 0) {
			setCourse(categories[0]);
			if (categories[0].categories.length > 0 && settings.selfordering.hide_home == 1)
				setCategory(categories[0].categories[0]);
		}
	};

	const initTablePayment = () => {
		setAlertData({
			title: <Label number={102} />,
			message: <Label number={122} />,
			showCancelButton: true,
			callback: async () => {
				const response = await Api.post("/pay/open/", {
					mode: "table",
					amount: cart.table_total,
					origin: "selfordering",
					table_id: table.id,
					shop_id: shop.id,
					self_cart_id: cart.cart_id,
					customer_id: user.id,
					self_mode: accessType,
				});
				if (response.success == 1) document.location = response.url;
				else alert(response.error);
			},
		});
	};

	const check_round_time = () => {
		console.log("check_round_time");
		if (cart.last_order) {
			var formatted_date = replaceAll(cart.last_order.delivery_date, "-", "/");
			const d = new Date();
			const d2 = new Date(formatted_date);
			var diff = d - d2;
			var remaining = shop.selfordering_round_time * 60000 - diff;
			var remaining_min = Math.floor(remaining / 60000);
			var remaining_sec = ((remaining % 60000) / 1000).toFixed(0);
			remaining_sec = remaining_sec < 10 ? "0" + remaining_sec : remaining_sec;
			var remaining_formatted = remaining_min + ":" + remaining_sec;
			//console.log("Actual time", d);
			//console.log("Last order time", d2);
			//console.log("Diff", diff);
			//console.log("Remaining min", remaining_min);
			//console.log("Remaining sec", remaining_sec);
			//console.log("remaining", remaining);
			//console.log("remaining_formatted", remaining_formatted);
			if (remaining <= 0) {
				setTimerActive(false);
				//clearInterval(timer);
			} else setTimerActive(remaining_formatted);
		}
	};

	const open_temp_cart = async (shop_id, name) => {
		console.log("open_temp_cart");
		const tableID = Date.now();
		console.log(tableID);
		const response = await Api.post("/self_cart/open_temp_cart/" + tableID + "/", {
			shop_id: shop_id,
			name: name,
		});
		if (response.success === 0) alert(response.error);
		else {
			setTable({
				id: tableID,
			});
			setPin(tableID);
		}
	};

	const router = () => {
		//console.log("router", monoOrder, shop, table, cart.covers, pin);
		if (!pin) {
			if (kioskMode) return <KioskHome />;
			else if (monoOrder) return <Name />;
			else return <Pin />;
		} else if (
			!monoOrder &&
			table &&
			(!cart.covers || parseInt(cart.covers) == 0) &&
			settings.selfordering.disable_covers == 0
		)
			return <Covers />;
		else if (settings.selfordering.enable_customer_select_list == 1 && !table.list_id)
			return <Lists />;
		else return <Page />;
	};

	if (lock) return <Loading />;

	return (
		<GoogleOAuthProvider clientId={settings.google?.client_id}>
			<SettingsContext.Provider
				value={{
					settings,
					setSettings,
					lang,
					setLang,
					accessType,
					monoOrderConfirmVisible,
					setMonoOrderConfirmVisible,
					isPaymentUser,
					setIsPaymentUser,
					legal,
					setLegalPupop,
					setPagePopup,
					category,
					setCategory,
					course,
					setCourse,
					setIsClosed,
					credit,
					setCredit,
					load_menu,
					loadCart,
					loadSettings,
					editTable,
					labels,
					setLabels,
					userID,
					cartVisible,
					setCartVisible,
					userPopupVisible,
					setUserPopupVisible,
					selectedProduct,
					setSelectedProduct,
					setPromoPopup,
					setProductSheet,
					qrOrderConfirmVisible,
					setQrOrderConfirmVisible,
					discounts,
					setLoading,
					setOrdersVisible,
					initTablePayment,
					relatedProducts,
					setRelatedProducts,
					getRelatedProducts,
					setLoadRelated,
					timerActive,
					setTimerActive,
					monoOrder,
					setEmailPopupVisible,
					editCart,
					handleCheckout,
					showPaymentPopup,
					setShowPaymentPopup,
					checkout,
					closeCart,
					marketing,
					setMarketing,
					newsletter,
					setNewsletter,
					singleOrderMode,
					open_temp_cart,
					productNotesPopup,
					setProductNotesPopup,
					setKioskPaymentPopUpVisible,
					pin,
					setPin,
					kioskMode,
					pages,
					loadProductsStock,
					handleAddProduct,
					setQrPopupVisible,
					setCategoryHandler,
				}}
			>
				<ShopContext.Provider value={{ shop, setShop, load_shop, brand }}>
					<TableContext.Provider value={{ table, setTable, load_table }}>
						<CategoriesContext.Provider
							value={{
								categories,
								setCategories,
							}}
						>
							<CartContext.Provider value={{ cart, setCart, add_product, updateQty }}>
								<AlertContext.Provider value={{ alertData, setAlertData }}>
									<UserContext.Provider value={{ user, setUser, load_customer }}>
										{settings && shop && table && <Socket socket={socket} />}
										{router()}

										{cart.allyoucaneat == 1 && table && <Limits />}
										{ordersVisible && (
											<Orders setOrdersVisible={setOrdersVisible} />
										)}
										{cartVisible && (
											<Cart
												cartVisible={cartVisible}
												setCartVisible={setCartVisible}
											/>
										)}
										{userPopupVisible && (
											<User
												data={userPopupVisible}
												setUserPopupVisible={setUserPopupVisible}
											/>
										)}
										{emailPopupVisible && (
											<EmailPopup data={userPopupVisible} />
										)}
										{relatedProducts && <RelatedProductsPopup />}
										{productSheet && <ProductSheet product={productSheet} />}
										{selectedProduct && <Variants product={selectedProduct} />}
										{legalPopup && <LegalPopup content={legalPopup} />}
										{pagePopup && <PagePopup id={pagePopup} />}
										{promoPopup && <PromoPopup />}
										{flowPopUp && (
											<FlowPopUp
												data={flowPopUp}
												add_product={add_product}
												settings={settings}
											/>
										)}
										{kioskPaymentPopUpVisible && (
											<KioskPaymentPopUp order={kioskPaymentPopUpVisible} />
										)}

										{alertData && (
											<Alert
												alertData={alertData}
												setAlertData={setAlertData}
											/>
										)}
										{confirmAnimation && (
											<div className="confirm">
												<div>
													<Label number={16} />
												</div>
											</div>
										)}
										{monoOrderConfirmVisible && (
											<MonoOrderConfirm
												onClose={() => setMonoOrderConfirmVisible(false)}
											/>
										)}
										{qrOrderConfirmVisible && (
											<QrOrderConfirm
												order={qrOrderConfirmVisible}
												onClose={() => setQrOrderConfirmVisible(false)}
											/>
										)}
										{qrPopupVisible && (
											<>
												<div
													className="overlay"
													onClick={() => setQrPopupVisible(false)}
												></div>
												<QrPopup />
											</>
										)}
										{loading && <Loading />}
										{isClosed && <Lock message={isClosed} />}
										{isMaintenance && <Lock message={isMaintenance} />}
										{settings && settings.mandant.expired && (
											<div className="expire">
												<div className="content">
													<h3>ATTENZIONE</h3>
													Servizio non attivo
												</div>
											</div>
										)}
										<PopUps />
									</UserContext.Provider>
								</AlertContext.Provider>
							</CartContext.Provider>
						</CategoriesContext.Provider>
					</TableContext.Provider>
				</ShopContext.Provider>
			</SettingsContext.Provider>
		</GoogleOAuthProvider>
	);
}

export default App;
